<template>
  <v-container id="login" fluid class="fill-height justify-center align-center mt-5" tag="section">
    <v-row justify="center" align="center" class="sm-background">
      <v-slide-y-transition appear>
        <login-pages-card max-width="100%" width="440" height="100%" class="py-1 pb-3">
          <v-card-text class="text-center">
            <v-form v-model="formIsValid" @submit.prevent="validateBeforeSubmit">
              <p>
                {{ $t('verifyEmail.message') }}
              </p>
              <v-btn
                v-if="!showCodeInput"
                width="90%"
                rounded
                color="secondary"
                class="ma-2"
                @click="showCodeInput = !showCodeInput"
              >
                {{ $t('verifyEmail.verificationCode') }}
              </v-btn>
              <br />
              <v-text-field
                v-if="showCodeInput"
                id="verification_code"
                v-model="verify.code"
                :append-outer-icon="'mdi-send'"
                :rules="[rules.minLength]"
                name="verification_code"
                :label="$t('verifyEmail.codePlaceholder')"
                class="px-2"
                @click:append-outer="validateBeforeSubmit"
              />
              <v-btn
                rounded
                width="90%"
                color="secondary"
                class="ma-2"
                :loading="isLoading || isResendLoading"
                @click="resendEmail"
              >
                {{ $t('verifyEmail.resendEmail') }}
              </v-btn>
              <br />
              <v-btn rounded outlined width="90%" class="ma-2" @click="logout">
                {{ $t('log-out') }}
              </v-btn>
            </v-form>
          </v-card-text>
        </login-pages-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>
<script>
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {resendVerificationEmail} from '@/auth/api';
import {DASHBOARD} from '@/router/route-names';
import LoginPagesCard from '@/auth/components/LoginPagesCard';

export default {
  name: 'VerifyEmail',
  components: {LoginPagesCard},
  data() {
    return {
      rules: {
        minLength: (value) => value.length >= 20 || 'Min 20 characters',
      },
      showCodeInput: false,
      verify: {
        code: '',
      },
      formIsValid: '',
    };
  },
  computed: {
    verificationToken() {
      return this.$route.params.token;
    },
    isLoading() {
      return this.$loadingFlags.isLoading(LoadingFlag.VerifyUser);
    },
    isResendLoading() {
      return this.$loadingFlags.isLoading(LoadingFlag.ResendVerifyEmail);
    },
    redirectRoute() {
      return (
        this.$route.query.redirect || {
          name: DASHBOARD,
        }
      );
    },
  },
  created() {
    if (this.verificationToken) {
      this.verify.code = this.verificationToken;
      this.verifyEmail();
    }
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
    validateBeforeSubmit() {
      if (this.formIsValid) {
        return this.verifyEmail();
      }
    },
    verifyEmail() {
      this.$auth.verifyEmail(this.verify.code).then(() => {
        this.$router.push(this.redirectRoute);
      });
    },
    resendEmail() {
      this.$loadingFlags
        .loadingHandler(LoadingFlag.ResendVerifyEmail, resendVerificationEmail())
        .catch((err) =>
          this.$errorReporting.showErrorDialog(err, {
            text: 'There was an issue resending your verification e-mail. Please try again later.',
          })
        );
    },
  },
};
</script>
